import * as React from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation, Trans } from "react-i18next";
import buycrypto from "./Svgs/buycrypto.png";
import buycryptoColored from "./Svgs/buycryptoColored.png";
import deposit from "./Svgs/deposit.png";
import depositColored from "./Svgs/depositColored.png";
import swap from "./Svgs/swap.png";
import swapColored from "./Svgs/swapColored.png";
import vault from "./Svgs/vault.png";
import vaultColored from "./Svgs/vaultColored.png";
import withdrawimg from "./Svgs/withdraw.png";
import withdrawColored from "./Svgs/withdrawColored.png";
import Deposit from "./InnerTabsComponents/Deposit/Deposit";
import Buy from "./InnerTabsComponents/Buy/Buy";
import BCSwap from "./InnerTabsComponents/BcSwap/BcSwap";
import VaultPro from "./InnerTabsComponents/VaultPro/VaultPro";
import WithDraw from "./InnerTabsComponents/WithDraw/WithDraw";
import { useContext } from "react";
import { modalsControl } from "../../../../Contexts/ModalsControl";
import AssetsPortfolio from "../../../AssetsPortfolio/AssetsPortfolio";
import { betPriceContext } from "../../../../Contexts/BetPrice";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function InnerTabs() {
  const theme = useTheme();
  const {
    referalBonusSlide,
    setReferalBonusSlide,
    value,
    setValue,
    assetsPortfolio,
    setAssetsPortfolio,
    fiatDepositMethod,
    setFiatDepositMethod,
  } = useContext(modalsControl);
  const { setOptionState } = useContext(betPriceContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const BackHandle = () => {
    setReferalBonusSlide(false);
  };
  const { t } = useTranslation();
  const activeCurrency = (id, isUsdt, currency) => {
    if (fiatDepositMethod) {
      setFiatDepositMethod(false);
    }
    // alert("enabling");
    setOptionState(currency.network_id);
    setAssetsPortfolio(false);
  };
  return (
    <Box
      className="wallet-pack-container relative"
      ls={{ bgcolor: "background", width: 200 }}
    >
      <div
        style={{
          zIndex: 1,
          right: referalBonusSlide ? "0" : "-120%",
          transition: ".5s",
        }}
        className="deposit-bonus-slide absolute width height"
      >
        <div className="wallet-popup-steps-slide text-justify padding-LeftRight height">
          <p className="secondary_text">{t("header.wallet.mostpara")}</p>
          <p className="secondary_text">{t("header.wallet.mostparaone")}</p>
          <p className="secondary_text">{t("header.wallet.notonly")}</p>
          <div className="item">
            <p className="title">{t("header.wallet.fristdeposit")} </p>
            <div className="word secondary_text">
              {t("header.wallet.fristbonce")}
            </div>
            <div className="deposit-level-info first">
              {/* <p className="b">150%</p>
              <p className="b">200%</p>
              <p className="y">250%</p> */}
            </div>
          </div>
          <div className="item">
            <p className="title">{t("header.wallet.secondbon")}</p>
            <div className="word secondary_text">
              {t("header.wallet.seconddep")}
            </div>
            <div className="deposit-level-info second">
              {/* <p className="b">150%</p>
              <p className="b">200%</p>
              <p className="y">250%</p> */}
            </div>
          </div>
          <div className="item">
            <p className="title">{t("header.wallet.thirdbon")}</p>
            <div className="word secondary_text">{t("header.wallet.thirddep")}</div>
            <div className="deposit-level-info third">
              {/* <p className="w">150%</p>
              <p className="w">200%</p>
              <p className="y">250%</p> */}
            </div>
          </div>
          <div className="item last">
            <p className="title">{t("header.wallet.fourthbon")}</p>
            <div className="word secondary_text">
              {t("header.wallet.fourthdep")}
            </div>
            <div className="deposit-level-info fourth">
              {/* <p className="w">200%</p>
              <p className="w">250%</p>
              <p className="y">300%</p> */}
            </div>
            <div className="centered_text">
              <button className="primary-btn primary-btn-bg" onClick={BackHandle}>
                {t("header.wallet.back")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          zIndex: 1,
          left: assetsPortfolio ? "0" : "-120%",
          transition: ".5s",
        }}
        className="deposit-bonus-slide absolute width height"
      >
        <div className="wallet-popup-steps-slide height">
          <AssetsPortfolio
            activeCurrency={activeCurrency}
            first={false}
            second={false}
          />
        </div>
      </div>

      <AppBar position="static">
        <Tabs
          style={{
            background: "#1e2024",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px",
          }}
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          aria-label="full width tabs example"
          className="justified"
        >
          <button
            className="justified inner-tabs-button"
            style={{
              border: "2px solid #1e2024",
              background: value === 0 ? "#2d3035" : "#17181b",
              fontWeight: "bold",
              color: "#fff",
              width: value === 0 ? "25%" : "6%",
              overflow: "hidden",

              padding: "15px 0",
              margin: "0 10 0 0px",
              borderRadius: "5px",
            }}
            tabindex="0"
            type="button"
            role="tab"
            aria-selected="true"
            id="full-width-tab-1"
            aria-controls="full-width-tabpanel-1"
            onClick={() => {
              handleChangeIndex(0);
            }}
          >
            <img
              style={{
                maxWidth: "25px",
                marginRight: value === 0 ? "5px" : "0",
              }}
              src={value === 0 ? deposit : depositColored}
              alt=""
            />
            <span
              style={{
                display: value === 0 ? "block" : "none",
              }}
            >
              {t("header.wallet.deposit")}
            </span>
          </button>
          <button
            className="justified inner-tabs-button"
            style={{
              background: "transparent",
              border: "2px solid #1e2024",
              background: value === 1 ? "#2d3035" : "#17181b",
              fontWeight: "bold",
              color: "#fff",
              width: value === 1 ? "25%" : "6%",
              overflow: "hidden",

              padding: "15px 0",
              margin: "0 10 0 10px",
              borderRadius: "5px",
            }}
            tabindex="1"
            type="button"
            role="tab"
            aria-selected="false"
            id="full-width-tab-2"
            aria-controls="full-width-tabpanel-2"
            onClick={() => {
              handleChangeIndex(1);
            }}
          >
            <img
              style={{
                maxWidth: "25px",
                marginRight: value === 1 ? "5px" : "0",
              }}
              src={value === 1 ? buycrypto : buycryptoColored}
              alt=""
            />
            <span
              style={{
                display: value === 1 ? "block" : "none",
              }}
            >
              {t("header.wallet.buy")}
            </span>
          </button>
          <button
            className="justified inner-tabs-button"
            style={{
              background: "transparent",
              border: "2px solid #1e2024",
              background: value === 2 ? "#2d3035" : "#17181b",
              fontWeight: "bold",
              color: "#fff",
              width: value === 2 ? "25%" : "6%",
              overflow: "hidden",

              padding: "15px 0",
              margin: "0 10px",
              borderRadius: "5px",
            }}
            tabindex="2"
            type="button"
            role="tab"
            aria-selected="true"
            id="full-width-tab-3"
            aria-controls="full-width-tabpanel-3"
            onClick={() => {
              handleChangeIndex(2);
            }}
          >
            <img
              style={{
                maxWidth: "25px",
                marginRight: value === 2 ? "5px" : "0",
              }}
              src={value === 2 ? vault : vaultColored}
              alt=""
            />
            <span
              style={{
                display: value === 2 ? "block" : "none",
              }}
            >
              {t("header.wallet.vault")}
            </span>
          </button>
          <button
            className="justified inner-tabs-button"
            style={{
              background: "transparent",
              border: "2px solid #1e2024",
              background: value === 3 ? "#2d3035" : "#17181b",
              fontWeight: "bold",
              color: "#fff",
              width: value === 3 ? "25%" : "6%",
              overflow: "hidden",

              padding: "15px 0",
              margin: "0 10px",
              borderRadius: "5px",
            }}
            tabindex="3"
            type="button"
            role="tab"
            aria-selected="true"
            id="full-width-tab-4"
            aria-controls="full-width-tabpanel-4"
            onClick={() => {
              handleChangeIndex(3);
            }}
          >
            <img
              style={{
                maxWidth: "25px",
                marginRight: value === 3 ? "5px" : "0",
              }}
              src={value === 3 ? swap : swapColored}
              alt=""
            />
            <span
              style={{
                display: value === 3 ? "block" : "none",
              }}
            >
              {t("header.wallet.swap")}
            </span>
          </button>
          <button
            className="justified inner-tabs-button"
            style={{
              background: "transparent",
              border: "2px solid #1e2024",
              background: value === 4 ? "#2d3035" : "#17181b",
              fontWeight: "bold",
              color: "#fff",
              width: value === 4 ? "25%" : "6%",
              overflow: "hidden",

              padding: "15px 0",
              margin: "0 0px 0 10px",
              borderRadius: "5px",
            }}
            tabindex="4"
            type="button"
            role="tab"
            aria-selected="true"
            id="full-width-tab-5"
            aria-controls="full-width-tabpanel-5"
            onClick={() => {
              handleChangeIndex(4);
            }}
          >
            <img
              style={{
                maxWidth: "25px",
                marginRight: value === 4 ? "5px" : "0",
              }}
              src={value === 4 ? withdrawimg : withdrawColored}
              alt=""
            />
            <span
              style={{
                display: value === 4 ? "block" : "none",
              }}
            >
              {t("header.wallet.withdraw")}
            </span>
          </button>

          {/* <Tab
            className="sisthTab wallerInnerButtons"
            label={<img className="img-svg" src={Piggy} />}
            {...a11yProps(5)}
          /> */}
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <Deposit />
        </TabPanel>

        <TabPanel value={value} index={1} dir={theme.direction}>
          <Buy />
        </TabPanel>

        <TabPanel value={value} index={2} dir={theme.direction}>
          <VaultPro />
        </TabPanel>

        <TabPanel value={value} index={3} dir={theme.direction}>
          <BCSwap />
        </TabPanel>

        <TabPanel value={value} index={4} dir={theme.direction}>
          <WithDraw />
        </TabPanel>

        {/* <TabPanel value={value} index={5} dir={theme.direction}>
          <NFT />
        </TabPanel> */}
      </SwipeableViews>
    </Box>
  );
}
