import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { betPriceContext } from "../../Contexts/BetPrice";
import ShowComponent from "../../Common/ShowComponent/ShowComponent";
import axios from "axios";
import BetConfigration from "../../../api/currency_bet_configrations";
const Provider = () => {
  const { activatedCurrency } = useContext(betPriceContext);
  const { t } = useTranslation();

  const mines = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24,
  ];
  const [configuredCurrency, setConfiguredCurrency] = useState({});
  const [currencyConfigrations, setCurrencyConfigrations] = useState([]);

  // Helper functions
  const findConfiguredCurrency = () => {
    setConfiguredCurrency(
      currencyConfigrations?.find(
        (currency) => currency.network_id === activatedCurrency.network_id
      )
    );
  };
  const inputInitials = {
    betAmount: 1,
    mines: 1,
    network_id: activatedCurrency.network_id,
  };
  const [betValues, setBetValues] = useState(inputInitials);
  const [betMines, setMines] = useState(1);
  const [currentMultiplier, setCurrentMultiplier] = useState(1);
  const [nextMultiplier, setnextMultiplier] = useState(1);
  const [inprogressBet, setInprogressBet] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [tokenSent, setTokenSent] = useState(false);
  const setToken = () => {
    setTokenSent(true);
    sendData({
      message: "auth token",
      type: "authToken",
      value: { auth: gamesData.token, mines: betMines },
    });
  };
  const handleOnChange = (e) => {
    if (inprogressBet) {
      return;
    }
    const { name, value } = e.target;

    setBetValues({ ...betValues, [name]: value });
  };
  const handleToggleSlider = () => {
    if (inprogressBet) {
      return;
    }
    setShowSlider(!showSlider);
  };
  const handleBetAmountBlur = (e) => {
    const { name, value } = e.target;
    if (!value || parseFloat(value) < 0) {
      minBetSetter();
    } else if (parseFloat(value) < configuredCurrency?.min_bet) {
      minBetSetter();
    } else if (parseFloat(value) > configuredCurrency?.max_bet) {
      maxBetSetter();
    } else {
      setBetValues({
        ...betValues,
        [name]: parseFloat(value).toFixed(configuredCurrency?.precision),
      });
    }
  };
  const minBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.min_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const maxBetSetter = () => {
    setBetValues({
      ...betValues,
      betAmount: parseFloat(configuredCurrency?.max_bet).toFixed(
        configuredCurrency?.precision
      ),
    });
  };
  const multiplyBetAmount = () => {
    if (inprogressBet) {
      return;
    }
    if (parseFloat(betValues?.betAmount) * 2 < configuredCurrency?.max_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) * 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      maxBetSetter();
    }
  };
  const divideBetAmount = () => {
    if (inprogressBet) {
      return;
    }
    if (parseFloat(betValues?.betAmount) / 2 > configuredCurrency?.min_bet) {
      setBetValues({
        ...betValues,
        betAmount: (parseFloat(betValues?.betAmount) / 2).toFixed(
          configuredCurrency?.precision
        ),
      });
    } else {
      minBetSetter();
    }
  };
  const handleMinBetAmount = () => {
    minBetSetter();
  };
  const handleMaxBetAmount = () => {
    maxBetSetter();
  };
  const [gamesData, setGamesData] = useState({});
  const [loadingGame, setLoadingGame] = useState(false);

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "mines") {
      sendData({
        message: "bet mines",
        type: "betMinesSent",
        value: value,
      });
    }
    setMines(value);
  };
  const data = {
    message: "decreasecounter",
    type: "decrement",
    value: "value",
  };
  // Event sender
  const sendData = (eventData) => {
    console.log("event sending");
    const iframe1 = document.getElementById("iframe1");
    iframe1.contentWindow.postMessage(
      eventData,
      "https://provider-frontend.pages.dev/"
    );
  };

  const createBetResponse = (data) => {
    setInprogressBet(data.inProgressBet);
    setCurrentMultiplier(data.currentMultiplier);
    setnextMultiplier(data.nextMultiplier);
    if (data.selectedMines) {
      setMines(data.selectedMines);
    }
  };

  // Availiable response
  const availableResponse = {
    betFunctions: (data) => createBetResponse(data),
  };
  // Event receiver
  const receiveMessageFromChild = (event) => {
    const { type, value } = event.data;
    if (type) {
      availableResponse[type](value);
    }
  };

  // Event publisher effect
  useEffect(() => {
    window.addEventListener("message", receiveMessageFromChild);
    return () => {
      window.removeEventListener("message", receiveMessageFromChild);
    };
  }, []);

  const createBet = () => {
    sendData({
      message: "Create the bet",
      type: "createBet",
      value: { betMines, auth: gamesData.token },
    });
  };

  const handleCashout = () => {
    sendData({
      message: "Cashout the bet",
      type: "cashout",
      value: { betMines, auth: gamesData.token },
    });
  };

  useEffect(() => {
    setLoadingGame(true);
    axios
      .post(`${process.env.REACT_APP_PROVIDER_LINK}/api/v1/provider/get_game`, {
        provider_data: {
          game: {
            name: "mines",
            "variant":"space"
          },
          user_id: 1,
          api_token: `${process.env.REACT_APP_PROVIDER_KEY}`,
        },
      })
      .then((response) => {
        setGamesData(response.data.data);
        setLoadingGame(false);
        setTimeout(() => {}, 9000);
      })
      .catch((err) => {
        setLoadingGame(false);
        console.warn(err);
      });
  }, []);
  useEffect(() => {
    BetConfigration.bet_configrations("mines")
      .then((res) => {
        setCurrencyConfigrations(res.data.currency_configuration);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    findConfiguredCurrency();
  }, [currencyConfigrations.length, activatedCurrency.network_id]);
  useEffect(() => {
    if (!configuredCurrency?.min_bet) {
      return;
    }
    minBetSetter();
  }, [configuredCurrency?.min_bet, activatedCurrency.network_id]);
  return (
    <div className="main-wrapper-container">
      <div className="row m-0">
        <div className="col-lg-9 mb-4 p-0 dark-bg-four">
          {loadingGame ? (
            <h3>Loading game ...</h3>
          ) : !gamesData.url ? (
            <h2>Unable to load the game</h2>
          ) : (
            <div className="relative">
              <ShowComponent condition={!tokenSent}>
                <div
                  style={{
                    background: "#00000075",
                    height: "100%",
                    width: "100%",
                    top: "0",
                  }}
                  className="justified absolute"
                ></div>
                <button
                  style={{
                    color: "white",
                  }}
                  className="absolute orbitron-font primary-btn primary-btn-btn bet-button-bubbles"
                  onClick={setToken}
                >
                  Play
                </button>
              </ShowComponent>

              <iframe
                id="iframe1"
                src={gamesData.url}
                // src="http://localhost:5173/mines/land"
                title="Microfrontend"
                height="764px"
              ></iframe>
            </div>
          )}
        </div>
        <div className="col-lg-3 mb-4 p-0 dark-bg-four order-lg-first">
          <div className="p-3 game-play-area">
            <div className="d-flex text-center gap-1 fs-14">
              <button
                id="manual"
                className="w-100 py-3 radius-3 orbitron-font dark-bg-two secondary_text"
              >
                {t("header.referral.manual")}
              </button>
              <button
                id="auto"
                className={`w-100 py-3 radius-3 orbitron-font  
                   "dark-bg-two secondary_text"  
                `}
              >
                {t("header.referral.auto")}
              </button>
            </div>
            <div className="mt-4 mb-4">
              <div className="">
                <div className="position-relative">
                  <img
                    src={`/imgs/AcceptedNetworks/${activatedCurrency?.network_id}.webp`}
                    alt={activatedCurrency?.network_id}
                    className="ms-3 position-absolute currency-icons top-50 translate-middle-y"
                  />
                  <div className="d-flex end-0 me-2 position-absolute top-50 translate-middle-y">
                    <button
                      className="dark-bg-one ms-1 p-2 radius-3 text-color-secondary cursor-pointer"
                      onClick={divideBetAmount}
                    >
                      <small className="fs-13">/</small>2
                    </button>
                    <button
                      className="dark-bg-one ms-1 p-2 radius-3 text-color-secondary cursor-pointer"
                      onClick={multiplyBetAmount}
                    >
                      x2
                    </button>
                    <button
                      className="cursor-pointer dark-bg-one ms-1 p-2 radius-3 text-color-secondary range-slider-icon"
                      onClick={handleToggleSlider}
                    >
                      <img
                        src="/assets/plinko/svg/price-slider.svg"
                        alt=""
                        style={{ width: "10px" }}
                      />
                    </button>
                  </div>
                  <input
                    type="number"
                    name="betAmount"
                    className="form-control ps-5"
                    placeholder={t("stats.highrollers.betamount")}
                    value={betValues.betAmount}
                    onChange={handleOnChange}
                    onBlur={handleBetAmountBlur}
                    disabled={inprogressBet}
                  />
                </div>
              </div>
              <ShowComponent condition={showSlider}>
                <div className={`mt-4 d-flex gap-3 align-items-center`}>
                  <button
                    className="dark-bg-one fs-13 p-2 radius-3 text-color-secondary"
                    onClick={handleMinBetAmount}
                  >
                    {t("header.wallet.withdrawTab.min")}
                  </button>
                  <input
                    type="range"
                    name="betAmount"
                    className="form-range"
                    id="customRange1"
                    step="0.000001"
                    disabled={inprogressBet}
                    value={betValues?.betAmount}
                    onChange={handleOnChange}
                    min={parseFloat(configuredCurrency?.min_bet)}
                    max={parseFloat(configuredCurrency?.max_bet).toFixed(
                      configuredCurrency?.precision
                    )}
                  />
                  <button
                    className="dark-bg-one fs-13 p-2 radius-3 text-color-secondary"
                    onClick={handleMaxBetAmount}
                  >
                    {t("header.wallet.swapTab.max")}
                  </button>
                </div>
              </ShowComponent>

              <div className="mt-4">
                <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
                  <p className="mb-0">{t("header.sidebar.Mine")}</p>
                </div>
                <div className="input-label-container input-color">
                  <div>
                    <select
                      className="form-control uppercase"
                      name="mines"
                      id="mines"
                      value={betMines}
                      onChange={onChange}
                      // disabled={inProgressBet}
                    >
                      {mines.map((mine, index) => (
                        <option value={mine} key={index}>
                          {mine}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <ShowComponent condition={inprogressBet}>
              <div>
                <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
                  <p className="mb-0">
                    {t("header.sidebar.ProfitonNextTile")} &nbsp;
                    {nextMultiplier}X
                  </p>
                </div>
                <input
                  type="number"
                  name="nextmultiplier"
                  className="form-control mb-3"
                  value={betValues.betAmount * nextMultiplier}
                  disabled
                />
                <div className="d-flex justify-content-between mb-2 fs-14 text-color-secondary">
                  <p className="mb-0">
                    {t("header.sidebar.TotalProfit")} &nbsp;
                    {currentMultiplier}X
                  </p>
                </div>
                <input
                  type="number"
                  name="currentmultiplier"
                  className="form-control"
                  value={betValues.betAmount * currentMultiplier}
                  disabled
                />

                <button
                  className="orbitron-font primary-btn bet-button-bubbles-cancel w-100 mb-3 mt-4"
                  // onClick={RandomlyTile}
                >
                  {t("header.sidebar.tail")}
                </button>
                <div className="">
                  <button
                    className="orbitron-font primary-btn primary-btn-bg bet-button-bubbles w-100"
                    onClick={handleCashout}
                  >
                    {t("gamebox.betit.payout")}
                  </button>
                </div>
              </div>
            </ShowComponent>
            <ShowComponent condition={!inprogressBet}>
              <div className="">
                <button
                  className="orbitron-font primary-btn primary-btn-bg bet-button-bubbles w-100"
                  onClick={createBet}
                  disabled={!tokenSent}
                >
                  Play
                  {/* {t("gamebox.betit.placebet")} */}
                </button>
              </div>
            </ShowComponent>

            {/* <ShowComponent condition={alertMessage.length}> */}
            {/* <div className="alert alert-danger m-0 mt-4 mb-4 text-center"> */}
            {/* {alertMessage} */}
            {/* Error message */}
            {/* </div> */}
            {/* </ShowComponent> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Provider;
