import React from "react";

const AccordionItem = ({ level, vipFrom, vipTo, onClick }) => {
  return (
    <div
      className="align-items-center d-flex dark-bg-three mt-3 p-2 radius-4 pointer position-relative vip-accordian"
      id={level}
      onClick={onClick}
    >
      <div className="me-3">
        <img src={`/imgs/vip-club/${level}.png`} className="w-auto" alt="" />
      </div>
      <div className="flex-fill">
        <div className="d-flex justify-content-between align-items-center ps-2">
          <div>
            <p className="fs-18 fw-bold mb-1">{level}</p>
            <p>
              VIP {vipFrom} - VIP {vipTo}
            </p>
          </div>
          <div>
            <img
              src="/imgs/vip-club/vip-club-accrodian-icon.png"
              className="w-auto"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
