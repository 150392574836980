import React, { useState, memo } from "react";
import KenoBox from "./KenoBox/KenoBox";
import ShareAblePlayArea from "../Shareables/ShareAblePlayArea/ShareAblePlayArea";
import MutualMods from "./Mods/MutualMods";
import Undermaintenance from "../../Common/Undermaintenance";
import "./keno.css";

const Keno = () => {
  const generateArray = (length) => {
    let hashMap = {};
    for (let i = 0; i < length; i++) {
      let item = { type: "static" };
      hashMap[i + 1] = item;
    }
    return hashMap;
  };
  const [kenoBoxes, setKenoBoxes] = useState(generateArray(40));
  const [selectedBoxes, setSelectedBoxes] = useState([]);

  const [mode, setMode] = useState("manual");
  const removeBox = (id) => {
    console.log("selected boxes ::: ", selectedBoxes);
    setKenoBoxes((kenoBoxes) => ({
      ...kenoBoxes,
      [id]: { ...kenoBoxes[id], type: "static" },
    }));
    if (selectedBoxes.includes(id)) {
      setSelectedBoxes(selectedBoxes.filter((item) => item !== id));
    }
  };
  const addBox = (id) => {
    if (selectedBoxes.length < 10) {
      setKenoBoxes((kenoBoxes) => ({
        ...kenoBoxes,
        [id]: { ...kenoBoxes[id], type: "selected" },
      }));
      if (selectedBoxes.includes(id)) {
        return;
      } else {
        setSelectedBoxes([...selectedBoxes, id]);
      }
    }
  };
  const boxAvaialableClasses = {
    keno_static: addBox,
    keno_clicked: removeBox,
  };
  const selectBox = (id, className) => {
    console.log("selectedBoxes in function ::: ", selectedBoxes);
    boxAvaialableClasses[className](parseFloat(id));
  };
  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  let randomPick = async () => {
    for (let i = 0; selectedBoxes.length < 10; i++) {
      console.log("selectedBoxes in loop ::: ", selectedBoxes);
      let random = Math.floor(Math.random() * 40 + 1);
      selectBox(random, "keno_static");
      if (selectedBoxes.includes(random)) {
        return;
      } else {
        setSelectedBoxes([...selectedBoxes, random]);
      }
      await sleep(100);
    }
  };
  return (
    <Undermaintenance
      type="soon"
      time=""
      support={false}
      p1="For our users to experience wonderful playtime,"
      p2="we are about to release new games. Stay tuned!"
    />
  );
  return (
    <div className="main-wrapper-container">
      <div className="row m-0">
        <div className="col-lg-9 mb-4 p-0 dark-bg-four">
          <div className="keno-game p-3 pb-0">
            <div className="align-content-center d-flex justify-content-start">
              <ul className="align-items-center d-flex flex-fill justify-content-end games-trends-bar gap-2 orbitron-font fs-14">
                <li className="dark-bg-one" onClick={randomPick}>
                  BET HISTORY
                </li>
              </ul>
            </div>
            <div className="keno-digits position-relative">
              {/* <div className="keno-cashout-popup-containert d-flex">
				   		<div className="keno-cashout-popup">
				   			<p className="text-success">
				   				1.37000000&nbsp;
				   				<img src="/imgs/AcceptedNetworks/wb.webp" alt="" />
				   			</p>
				   			<p>1.37X</p>
				   		</div>
				   </div> */}

              {Object.keys(kenoBoxes).map((box) => (
                <KenoBox
                  variant={kenoBoxes[box].type}
                  onClick={(e) => selectBox(e.target.id, e.target.className)}
                  value={box}
                />
              ))}
            </div>
            <div className="keno-payout">
              <div>0.00×</div>
              <div>0.00×</div>
              <div>0.00×</div>
              <div>1.40×</div>
              <div>2.25×</div>
              <div>4.50×</div>
              <div>8.00×</div>
              <div>17.00×</div>
              <div>50.00×</div>
              <div>80.00×</div>
              <div>100.00×</div>
            </div>
            <div className="keno-select">
              <div>0&nbsp;Hits</div>
              <div>1&nbsp;Hits</div>
              <div>2&nbsp;Hits</div>
              <div>3&nbsp;Hits</div>
              <div>4&nbsp;Hits</div>
              <div>5&nbsp;Hits</div>
              <div>6&nbsp;Hits</div>
              <div>7&nbsp;Hits</div>
              <div>8&nbsp;Hits</div>
              <div>9&nbsp;Hits</div>
              <div>10&nbsp;Hits</div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 mb-4 p-0 dark-bg-four order-lg-first">
          <div className="p-3 game-play-area">
            <ShareAblePlayArea
              modsAvaiable={true}
              mode={mode}
              setMode={setMode}
            >
              <MutualMods selectedBoxes={selectedBoxes} />
            </ShareAblePlayArea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Keno);
